import React from "react";
import theme from "theme";
import { Theme, Text, Em, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				PrimeFit Arena
			</title>
			<meta name={"description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:title"} content={"PrimeFit Arena"} />
			<meta property={"og:description"} content={"From high-intensity interval training (HIIT) to calming yoga classes, each visit offers a new opportunity to challenge yourself and achieve your goals."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-1.jpg?v=2024-02-14T12:13:02.762Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Contact information
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ready for transformation?
							<br />
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<br />
						</Em>
						Call Us{" "}
						<br />
						+61249892719
						<br />
						<br />
						Or visit Us directly
						<br />
						16 George St, Highfields NSW 2289, Australia
					</Text>
					<Text margin="2rem 0px 30px 0px" font="--headline3">
						PrimeFit Arena - where fitness goals meet personal triumph
					</Text>
					<Button
						background="#5448fd"
						padding="12px 28px 12px 28px"
						border-radius="4px"
						font="normal 400 18px/1.5 --fontFamily-sans"
						margin="0px 0px 60px 0px"
						sm-margin="0px 0px 40px 0px"
						type="link"
						href="tel:+61249892719"
						text-decoration-line="initial"
					>
						Call Us
					</Button>
				</Box>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12:13:02.771Z"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/3-4.jpg?v=2024-02-14T12%3A13%3A02.771Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 0px -160px"
				sm-flex-direction="column"
				sm-margin="0px 0px 0px 0"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12:13:02.751Z"
					display="block"
					width="100%"
					border-radius="25px"
					margin="80px 0px 0px 0px"
					object-fit="cover"
					sm-margin="0 0px 0px 0px"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-2.jpg?v=2024-02-14T12%3A13%3A02.751Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12:13:02.763Z"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/1-3.jpg?v=2024-02-14T12%3A13%3A02.763Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12:13:02.752Z"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					margin="0px 0px 80px 0px"
					sm-margin="0px 0px 0 0px"
					srcSet="https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c4d8e2e8e00217813dd/images/2-1.jpg?v=2024-02-14T12%3A13%3A02.752Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="text2" />
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc500072400020e9c714"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});